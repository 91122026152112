















































import { getAuth, signOut } from "@firebase/auth";
import Vue from "vue";
import AppBarItem from "./AppBarItem.vue";

export default Vue.extend<any, any, any, any>({
  components: { AppBarItem },
  name: "DefaultAccount",
  computed: {
    profile() {
      return this.$store.getters.user;
    },
    role() {
      return this.$store.getters.role;
    },
  },
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.$store.dispatch("logout", { to: "/" });
        })
        .catch((e) => {
          console.log("e: ", e);
        });
    },
  },
});
